body {
  background-color: rgba(255, 255, 255, 0);
  padding: auto 20%;
}
.centered {
  margin: 20px auto 0;
  padding: 0 20%;
  text-align: center;
}

h2 {
  padding: 20px;
}
div .borderCSS {
  position: relative;
  display: block;
  margin: 0 auto 20px;
  clip-path: polygon(
    0% 0%,
    0% 100%,
    10% 100%,
    10% 10%,
    90% 10%,
    90% 90%,
    10% 90%,
    10% 100%,
    100% 100%,
    100% 0
  );
}

@keyframes gradient {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 20%;
  }
}

header {
  background-color: #8093a38e;
  color: rgb(255, 255, 255);
  margin: 0 0px 20px;
  padding: 8px 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

header h1 {
  color: rgb(54, 25, 11);
  font-weight: 60;
  vertical-align: center;
  font-size: 1.6em;
}

.testing {
  background-color: #000000;
}

.ref-button {
  width: 200px;
  height: 45px;
  border: 2px #eee;
  margin: 20px;
  color: #eee;
  font-weight: bold;
}

.copytoclipboard {
  text-align: center;
  position: relative;
  width: 500px;
  margin: 20px auto 1px auto;
  background: #fff;
  padding: 0px 1px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.grey {
  padding: 0;
  color: rgb(88, 88, 88);
  text-align: center;
  position: relative;
}

.sliderDiv {
  position: relative;
  width: 60%;
  margin: 10px auto;
  background: #fff;
  padding: 0px 12px 0px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}
.borderDiv {
  margin: auto auto 20px;
}



div .howtopar {
  margin: 20px auto 0;
  padding: 0 30%;
  text-align: center;
}

#navbarTitle{
  font-size: 1.6rem;
  color: white;
}
#navbarTitle:hover{
  font-size: 1.6rem;
  color: transparent;
}

.container:hover{
    background-image: linear-gradient(45deg, #ee7752,
    #e73c7e,
    #23a6d5, 
    #23d5ab);
    padding:0;
    -webkit-background-clip: text;  
  color: transparent;  
}

#navbarHowTo{
  font-weight: 600;
}
#navbarHowTo:hover{
  color: white;
}

.note{
  font-size: small;
  margin: 0;
}

.ref-button{
  margin: 10px auto;
}
